import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import DefaultLayout from "../containers/DefaultLayout";
import { GlobalProvider } from "../context/GlobalContext";
import HomePage from "../pages/Home/HomePage";
import ResetPassword from "../pages/Login/ForgotPassword";
import LoginPage from "../pages/Login/LoginPage";
import UpdatePassword from "../pages/Login/ResetPassword";
import AddUser from "../pages/Users/AddUser";
import Users from "../pages/Users/Users";
import AddProject from "../pages/Project/AddProject";
import Information from "../pages/Project/Information";
import ProjectDetails from "../pages/Project/ProjectDetails";
import ProjectList from "../pages/Project/ProjectList";
import UserDetails from "../pages/Project/UserDetails";

function AllRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(window.user ? true : false);

  useEffect(() => {
    getUser();
  }, [isAuthenticated]);

  const getUser = () => {
    if (window.user) {
      setIsAuthenticated(true);
    }
  };

  return isAuthenticated && window.user ? (
    <GlobalProvider>
      <DefaultLayout handleAuthState={setIsAuthenticated}>
        <Routes>
          <Route exact path="/home" element={<HomePage />} />
          <Route exact path="/projects" element={<ProjectList />} />
          <Route exact path="/project" element={<AddProject />} />
          <Route exact path="/project/:id" element={<AddProject />} />
          <Route exact path="/project/info/:pid" element={<ProjectDetails />} />
          {/* <Route exact path="/project/info/:id" element={<Information />} /> */}
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/user" element={<AddUser />} />
          <Route exact path="/user/:id" element={<AddUser />} />
          <Route exact path="/user/info/:uid/:pid" element={<UserDetails />} />
        </Routes>
      </DefaultLayout>
    </GlobalProvider>
  ) : (
    <Routes>
      <Route exact path="/login" element={<LoginPage handleAuthState={setIsAuthenticated} />} />
      <Route exact path="/forgot_password" element={<ResetPassword />} />
      <Route exact path="/reset/:token" element={<UpdatePassword />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default AllRoutes;
