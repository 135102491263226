import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "react-bootstrap/Pagination";
import ProjectService from "../../services/ProjectService";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_WEB_SOCKET_URL);

function ProjectList() {
  const projServ = new ProjectService();
  const [projList, setProjList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const [search, setSearch] = useState({
    start: 0,
    perPage: 10,
    searchTxt: "",
  });

  useEffect(() => {
    let user = window.user.result;
    socket.on("connect", () => {
      socket.emit("initiateConnAdmin", { user_id: user.id }, async (response) => {
        console.log(response);
      });
    });

    socket.on("newUserConnectedAdmin", (response) => {
      console.log("newUserConnectedAdmin", response);
      let projects = JSON.parse(JSON.stringify(projList));
      projects = projects.map((p) => {
        if (p.id == response.project_id) {
          p.connected_users = response.connected_users;
          p.total_users = response.total_users;
        }
        return p;
      });
      toast.info(response.project_name + " user connected");
      setProjList(projects);
    });

    socket.on("userDisconnectedAdmin", (response) => {
      console.log("userDisconnectedAdmin", response);
      let projects = JSON.parse(JSON.stringify(projList));
      projects = projects.map((p) => {
        if (p.id == response.project_id) {
          p.connected_users = response.connected_users;
          p.total_users = response.total_users;
        }
        return p;
      });
      toast.info(response.project_name + " user disconnected");
      setProjList(projects);
    });

    return () => {
      socket.off("connect");
      socket.off("newUserConnectedAdmin");
      socket.off("userDisconnectedAdmin");
    };
  }, [projList, setProjList, toast]);

  function searchText(e) {
    setSearch({ ...search, searchTxt: e.target.value });
  }

  async function getProjectList() {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        name: search.searchTxt,
      },
    };
    try {
      let response = await projServ.listAll(activity);
      if (response) {
        setProjList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const handlePaging = (pageNum) => {
    if (pageNum) {
      setSearch({ ...search, start: parseInt(pageNum) * search.perPage - search.perPage });
    }
  };

  let active = Math.ceil((search.start + 1) / search.perPage);
  let pages = Math.ceil(totalCount / search.perPage);

  let items = [];
  for (let number = 1; number <= pages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        onClick={() => handlePaging(number)}
        active={number === active}
        style={{ cursor: "pointer" }}
      >
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    getProjectList();
  }, [search]);

  const deleteProject = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this record?")) {
        let response = await projServ.delete(id);
        if (response.message) {
          getProjectList();
          toast.success(response.message);
        } else {
          toast.error("Record is not deleted ");
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const addProject = () => {
    navigate("/project");
  };

  return (
    <div className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative" id="ljpUserTables">
      <div className="user_part">
        <div className="users_bottom_part">
          <div
            className="d-flex flex-wrap user_bottom_header align-items-center page-header"
            style={{ justifyContent: "space-between" }}
          >
            <div className=" text-heading">
              <h4 className="mb-0">All Project</h4>
            </div>
            <div>
              <button type="primary" className="add-user-btn" onClick={addProject}>
                Add Project
              </button>
            </div>
          </div>
          {/* <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <div className="col-md-6 col-lg-6 col-sm-12 mt-0 ml-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12">
              <div className="input-group update_search_bar w-100">
                <button className="btn" type="button"></button>
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="Search Here"
                  onChange={(e) => {
                    searchText(e);
                  }}
                />
                <div className="input-group-append">
                  <button className="btn search_btn" type="submit">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          <div className="users_table mt-3">
            <div className="current_courses_table table-responsive">
              <table className="main_table w-100">
                <thead>
                  <tr>
                    <th>Identifier</th>
                    <th className="course_id">Name</th>
                    <th>Connected Users</th>
                    <th>Disconnected Users</th>
                    <th>Created at</th>
                    <th className="action_icons">ACTIONS</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {projList.map((record, i) => {
                    return (
                      <tr key={record.id}>
                        <td>
                          <Link to={"/project/info/" + record.id}>{record.uuid}</Link>
                        </td>
                        <td>{record.name}</td>
                        <td>{record.connected_users}</td>
                        <td>
                          {record.connected_users ? record.total_users - record.connected_users : record.total_users}
                        </td>
                        <td>{moment(record.createdAt).format("YYYY-MM-DD")}</td>
                        <td>
                          <div className="updates_icon p-0">
                            <Link
                              to="#"
                              onClick={() => {
                                deleteProject(record.id);
                              }}
                              className="delIconCustom fa-sharp me-1 fa-solid fa-trash"
                            ></Link>
                            <Link
                              className="editIconCustom fa-sharp me-1 fa-solid fa-pen-to-square"
                              to={"/project/" + record.id}
                            ></Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="users_table_footer">
            <Pagination size="sm">{items}</Pagination>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default ProjectList;
