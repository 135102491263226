import React, { useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import ProjectService from "../../services/ProjectService";
import { useNavigate, useParams } from "react-router-dom";

const projServe = new ProjectService();

export default function AddProject() {
  const navigate = useNavigate();
  const { id } = useParams();
  const ValidateSchema = Yup.object({
    name: Yup.string().required("Required"),
    url: Yup.string().required("Required"),
  });

  const [addEditProject, setAddEditProject] = new useState({
    name: "",
    connected_users: 0,
    total_users: 0,
  });

  useEffect(() => {
    fetchProject(id);
  }, []);

  const fetchProject = async (id) => {
    if (id) {
      const response = await projServe.getDetail(id);
      if (response) {
        setAddEditProject(response);
      }
    }
  };

  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };

  const onSubmit = async (values) => {
    let obj = { ...values };
    let response;
    try {
      delete obj.confirm_password;
      if (!id) {
        response = await projServe.add(obj);
      } else {
        response = await projServe.edit(obj);
      }
      if (response?.result) {
        toast(response.message);
        setTimeout(() => {
          navigate("/projects");
        }, 1500);
      } else {
        toast(response.error);
      }
    } catch (err) {
      err = JSON.parse(err.message);
    }
  };

  const formik = useFormik({
    initialValues: addEditProject,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <div>
      <section>
        <ToastContainer />
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row d-flex justify-content-center" style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="col-xl-10 col-lg-12 col-md-12">
              <form onSubmit={formik.handleSubmit}>
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{id ? "Edit" : "Add"} Project </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            placeholder="Project Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            name="name"
                            className={
                              "form-control" + (formik.touched.name && formik.errors.name ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.name && formik.errors.name ? dispErrorMsg("Project name is required") : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Project URL</label>
                          <input
                            type="text"
                            placeholder="url"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.url}
                            name="url"
                            className={"form-control" + (formik.touched.url && formik.errors.url ? " is-invalid" : "")}
                          />
                          {formik.touched.url && formik.errors.url ? dispErrorMsg("Project url is required") : null}
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-center">
                      <button type="submit" className="btn ripple  btn-primary add-user-form-submit-btn">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
