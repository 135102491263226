import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import UserService from "../../services/UserService";
import CommandService from "../../services/CommandService";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_WEB_SOCKET_URL);
function UserDetails() {
  const { uid, pid } = useParams();
  const [user, setUser] = useState({});
  const [dataSocket, setDataSocket] = useState("");
  const [commands, setCommands] = useState([]);
  const userServe = new UserService();
  const cmdServ = new CommandService();

  useEffect(() => {
    getUser();
    getCommandData();
  }, []);

  const getUser = async () => {
    if (uid) {
      const response = await userServe.getUser(uid);
      if (response) {
        delete response.password;
        setUser(response);
      }
    }
  };
  async function getCommandData() {
    let searchObj = { filter: { user_id: uid, project_id: pid } };
    let response = await cmdServ.commandList(searchObj);
    setCommands(response.data);
    if (response.dataSocket) {
      setDataSocket(response.dataSocket);
    }
  }

  useEffect(() => {
    let user = window.user.result;
    socket.on("connect", () => {
      socket.emit("initiateConnAdmin", { user_id: user.id }, async (response) => {
        console.log(response);
      });
    });

    socket.on("newUserConnectedAdmin", (response) => {
      console.log("newUserConnectedAdmin", response);
      if (pid == response.project_id && uid == response.user_id) {
        toast.info(response.username + " user connected");
        setDataSocket(response);
        let commandList = [
          { command: "connected", from: "user", user_id: response.user_id, project_id: response.project_id },
          ...commands,
        ];
        setCommands(commandList);
      }
    });

    socket.on("userDisconnectedAdmin", (response) => {
      console.log("userDisconnectedAdmin", response);
      if (pid == response.project_id && uid == response.user_id) {
        toast.info(response.username + " user disconnected");
        setDataSocket(response);
        let commandList = [
          { command: "disconnected", from: "user", user_id: response.user_id, project_id: response.project_id },
          ...commands,
        ];
        setCommands(commandList);
      }
    });
    socket.on("commandSavedAdmin", (response) => {
      console.log("commandSavedAdmin", response);
      if (pid == response.project_id && uid == response.user_id) {
        let commandList = [response, ...commands];
        setCommands(commandList);
      }
    });
  }, [commands, setCommands]);

  function sendCommand() {
    if (document.getElementById("inputCommand").value) {
      socket.emit(
        "commandAdmin",
        { user_id: uid, project_id: pid, command: document.getElementById("inputCommand").value },
        async (response) => {
          let commandList = [response.data, ...commands];
          setCommands(commandList);
        }
      );
    }
  }

  return (
    <div className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative" id="ljpUserTables">
      <div className="user_part">
        <div className=" text-heading">
          <h4 className="mb-0 users-connection">
            {user.username} - {dataSocket.ip_address}
          </h4>
          <div className="col-sm-8">
            <div className="input-box">
              <input type="text" placeholder="Choose Command" id="inputCommand" className="connection-input" />
              <button className="connection-button" onClick={sendCommand}>
                Send
              </button>
            </div>
          </div>
          <div className="users-connection-bottom">
            <ol className="connection-details">
              {commands.map((c) => (
                <li>
                  {c.from == "user" ? user.username : "admin"} : {c.command}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserDetails;
