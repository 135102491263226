import util from "../util/util";

export default class OfferService {
  add(payload) {
    return util.sendApiRequest("/project", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  delete(dataId) {
    return util.sendApiRequest("/project/" + dataId, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  getDetail(dataId) {
    return util
      .sendApiRequest("/project/" + dataId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  edit(payload) {
    return util.sendApiRequest("/project/", "PUT", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  listAll(payload) {
    return util.sendApiRequest("/project/list", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
}
