import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../services/UserService";
import Pagination from "react-bootstrap/Pagination";
import GlobalContext from "../../context/GlobalContext";

export default function Users() {
  const userServe = new UserService();
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  const [search, setSearch] = useState({
    start: 0,
    perPage: 10,
    searchTxt: "",
    searchField: "",
  });

  function searchText(e) {
    setSearch({ ...search, searchTxt: e.target.value });
  }

  async function getUserListApi() {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
      },
    };
    try {
      let response = await userServe.userListApi(activity);
      if (response) {
        setUserList(response.data);
        setTotalCount(response.total);
      } else {
        totalCount();
        userList();
      }
    } catch (err) {
      throw err;
    }
  }

  const handlePaging = (e) => {
    if (e.target.text) {
      search.start = parseInt(e.target.text) * search.perPage - search.perPage;
      getUserListApi();
    }
  };

  let active = Math.ceil((search.start + 1) / search.perPage);
  let pages = Math.ceil(totalCount / search.perPage);

  let items = [];
  for (let number = 1; number <= pages; number++) {
    items.push(
      <Pagination.Item key={number} onClick={handlePaging} active={number === active} style={{ cursor: "pointer" }}>
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    getUserListApi();
  }, [search]);

  const getCsvData = async () => {
    try {
      let response = await userServe.getCsvData(0, 10000);
      if (response.data) {
        setCsvResultList(response.data);
      }
    } catch (err) {}
  };

  const deleteUserRecord = async (userId) => {
    try {
      if (window.confirm("Are you sure you want to delete this record?")) {
        let response = await userServe.deleteUserRecordApi(userId);
        if (response.message) {
          getUserListApi();
          toast.success(response.message);
          setTotalCount(totalCount - 1);
          let userListTem = [...userList];

          const user = userListTem.filter((v) => {
            return v._id !== userId;
          });
          setUserList(user);
        } else {
          toast.error("Record is not deleted ");
        }
      }
    } catch (err) {
      throw err;
    }
  };
  const addUser = () => {
    navigate("/user");
  };
  const userEdit = async (id) => {
    navigate("/user/" + id);
  };
  const activeToggleHandler = async (value) => {
    try {
      let isActive = value.is_active;
      let obj = {
        is_active: !isActive,
        id: value.id,
      };
      let response = await userServe.edit(obj);
      if (response.message) {
        getUserListApi();
      }
    } catch (err) {
      toast.error("something went wrong!");
      throw err;
    }
  };
  const activeButton = (data) => {
    return (
      <div className="updates_icon d-flex align-items-center">
        <label className="press me-2">
          <input
            type="checkbox"
            className="cbx hidden"
            checked={data?.is_active}
            onChange={() => activeToggleHandler(data)}
          />
          <span className="lbl"></span>
        </label>
        {data?.is_active ? <label>Active</label> : <label>Inactive</label>}
      </div>
    );
  };
  const getList = async () => {
    getUserListApi();
  };

  return (
    <div className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative" id="ljpUserTables">
      <div className="user_part">
        <div className="users_bottom_part">
          <div
            className="d-flex flex-wrap user_bottom_header align-items-center page-header"
            style={{ justifyContent: "space-between" }}
          >
            <div className=" text-heading">
              <h4 className="mb-0">All Users</h4>
            </div>
            <div>
              <button type="primary" className="add-user-btn" onClick={addUser}>
                Add Admin User
              </button>
            </div>
          </div>
          {/* <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <div className="col-md-6 col-lg-6 col-sm-12 mt-0 ml-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12">
              <div className="input-group update_search_bar w-100">
                <button className="btn" type="button"></button>
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="Search Here"
                  onChange={(e) => {
                    searchText(e);
                  }}
                />
                <div className="input-group-append">
                  <button className="btn search_btn" type="submit">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          <div className="users_table mt-3">
            <div className="current_courses_table table-responsive">
              <table className="main_table w-100">
                <thead>
                  <tr>
                    <th>Userame</th>
                    <th className="action_icons">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {userList.map((user, i) => {
                    return (
                      <tr key={i}>
                        <td>{user.username}</td>
                        <td>
                          <div className="updates_icon p-0">
                            <Link
                              to="#"
                              onClick={() => {
                                deleteUserRecord(user.id);
                              }}
                              className="delIconCustom fa-sharp me-1 fa-solid fa-trash"
                            ></Link>
                            {user.role == 1 && (
                              <Link
                                className="editIconCustom fa-sharp me-1 fa-solid fa-pen-to-square"
                                onClick={() => userEdit(user.id)}
                              ></Link>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="users_table_footer">
            <Pagination size="sm">{items}</Pagination>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
