function Information() {
  return (
    <div
      className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative"
      id="ljpUserTables"
    >
      <div className="project-information ">
        <div className="project-user-information">user1</div>
        <div className="project-user-information project-user-information2">
          Set Username
        </div>
      </div>
      <div className="display-action">
        Display action
        <div className="display-action2">
          <div className="display-action3"></div>
        </div>
      </div>
    </div>
  );
}
export default Information;
