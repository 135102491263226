import React, { useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../services/UserService";
import ProjectService from "../../services/ProjectService";
import { useNavigate, useParams } from "react-router-dom";

const userServe = new UserService();
const projServ = new ProjectService();

export default function AddUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const ValidateSchema = Yup.object({
    username: Yup.string().required("Required"),
  });

  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [addEditUser, setAddEditUser] = new useState({
    username: "",
    project_id: "",
    password: "",
    role: 1,
    confirm_password: "",
    isConnected: 0,
  });

  useEffect(() => {
    fetchUserProfile(id);
  }, []);

  const fetchUserProfile = async (id) => {
    if (id) {
      const response = await userServe.getUser(id);
      if (response) {
        delete response.password;
        setAddEditUser(response);
      }
    }
  };

  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };

  const onSubmit = async (values) => {
    let obj = { ...values };

    setPasswordErrorMsg(0);
    console.log(id, obj.password, obj.confirm_password);
    if (!id && !obj.password) {
      setPasswordErrorMsg(1);
      return;
    }
    if (obj.password && obj.password != obj.confirm_password) {
      setPasswordErrorMsg(2);
      return;
    }

    let response;
    try {
      delete obj.confirm_password;
      if (!id) {
        response = await userServe.addUser(obj);
      } else {
        response = await userServe.edit(obj);
      }
      if (response?.result) {
        toast(response.message);
        setPasswordErrorMsg(0);
        setTimeout(() => {
          navigate("/users");
        }, 1500);
      } else {
        toast(response.error);
      }
    } catch (err) {
      err = JSON.parse(err.message);
    }
  };

  const formik = useFormik({
    initialValues: addEditUser,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <div>
      <section>
        <ToastContainer />
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row d-flex justify-content-center" style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="col-xl-10 col-lg-12 col-md-12">
              <form onSubmit={formik.handleSubmit}>
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{id ? "Edit" : "Add"} User </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Userame</label>
                          <input
                            type="text"
                            placeholder="First Name"
                            onChange={formik.handleChange}
                            value={formik.values.username}
                            name="username"
                            className={
                              "form-control" + (formik.touched.username && formik.errors.username ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.username && formik.errors.username
                            ? dispErrorMsg("Username is required")
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Password {id ? "(Optional)" : ""}</label>
                          <input
                            type="password"
                            placeholder={id ? "password(optional)" : "password"}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            name="password"
                            className={"form-control" + (passwordErrorMsg == 1 ? " is-invalid" : "")}
                          />
                          {passwordErrorMsg == 1 ? dispErrorMsg("Password is required") : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group form-group-margin-bottom">
                          <label className="form-label">Confirm Password {id ? "(Optional)" : ""}</label>
                          <input
                            type="password"
                            placeholder="password"
                            onChange={formik.handleChange}
                            value={formik.values.confirm_password}
                            name="confirm_password"
                            className={"form-control" + (passwordErrorMsg == 2 ? " is-invalid" : "")}
                          />
                          {passwordErrorMsg == 2 ? dispErrorMsg("Password and confirm password does not match.") : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-center">
                    <button type="submit" className="btn ripple  btn-primary add-user-form-submit-btn">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
