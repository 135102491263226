import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import UserService from "../../services/UserService";
import io from "socket.io-client";
const socket = io(process.env.REACT_APP_WEB_SOCKET_URL);

function ProjectDetails() {
  const userServe = new UserService();
  const { pid } = useParams();
  const [users, setUsers] = useState([]);
  async function getUserListApi() {
    let activity = {
      filter: {
        project_id: pid,
      },
    };
    try {
      let response = await userServe.userListApi(activity);
      if (response) {
        setUsers(response.data);
      }
    } catch (err) {
      throw err;
    }
  }

  useEffect(() => {
    getUserListApi();
  }, []);

  useEffect(() => {
    let user = window.user.result;
    socket.on("connect", () => {
      socket.emit("initiateConnAdmin", { user_id: user.id }, async (response) => {
        console.log(response);
      });
    });

    socket.on("newUserConnectedAdmin", (response) => {
      console.log("newUserConnectedAdmin", response);
      let usersList = JSON.parse(JSON.stringify(users));
      usersList = usersList.map((u) => {
        if (pid == response.project_id && u.id == response.user_id) {
          u.sockets[0].socket_id = response.socket_id;
          u.sockets[0].ip_address = response.ip_address;
          u.sockets[0].last_command = response.last_command;
          u.sockets[0].last_action = response.last_action;
        }
        return u;
      });
      toast.info(response.username + " user connected");
      setUsers(usersList);
    });

    socket.on("userDisconnectedAdmin", (response) => {
      console.log("userDisconnectedAdmin", response);
      let usersList = JSON.parse(JSON.stringify(users));
      usersList = usersList.map((u) => {
        if (pid == response.project_id && u.id == response.user_id) {
          u.sockets[0].socket_id = response.socket_id;
          u.sockets[0].ip_address = response.ip_address;
          u.sockets[0].last_command = response.last_command;
          u.sockets[0].last_action = response.last_action;
        }
        return u;
      });
      toast.info(response.username + " user disconnected");
      setUsers(usersList);
    });

    return () => {
      socket.off("connect");
      socket.off("newUserConnectedAdmin");
      socket.off("userDisconnectedAdmin");
    };
  }, [users, setUsers, toast]);

  return (
    <div className="ljSectionData w-100 clearfix ljpUserTables pb-4 p-relative" id="ljpUserTables">
      <div className="user_part">
        <div
          className="d-flex flex-wrap user_bottom_header align-items-center page-header"
          style={{ justifyContent: "space-between" }}
        ></div>
        <div className=" text-heading">
          <h4 className="mb-0 connection-heading">Connected Users</h4>
        </div>
        <div className="users_table mt-3">
          <div className="current_courses_table table-responsive">
            <table className="main_table w-100">
              <thead>
                <tr>
                  <th>UserName</th>
                  <th className="course_id">Latest Action</th>
                  <th>IP</th>
                </tr>
              </thead>
              <tbody>
                {users
                  .filter((u) => u.sockets && u.sockets[0] && u.sockets[0].socket_id != "")
                  .map((key, i) => {
                    return (
                      <tr key={i}>
                        <Link to={`/user/info/${key.id}/${pid}`}>
                          <td className="blue-color">
                            {key.username ? key.username : <span className="red-color"> Not Found </span>}
                          </td>
                        </Link>
                        <td>
                          {key.sockets[0].last_command ? (
                            key.sockets[0].last_command
                          ) : (
                            <span className="red-color"> Not Found </span>
                          )}
                        </td>
                        <td>
                          {key.sockets[0].ip_address ? (
                            key.sockets[0].ip_address
                          ) : (
                            <span className="red-color"> Not Found </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className=" text-heading">
          <h4 className="mb-0" style={{ textAlign: "center" }}>
            Disconnected Users
          </h4>
        </div>
        <div className="users_table mt-3">
          <div className="current_courses_table table-responsive">
            <table className="main_table w-100">
              <thead>
                <tr>
                  <th>UserName</th>
                  <th className="course_id">Latest Action</th>
                  <th>IP</th>
                </tr>
              </thead>
              <tbody>
                {users
                  .filter((u) => !u.sockets || !u.sockets[0] || u.sockets[0].socket_id == "")
                  .map((key, i) => {
                    return (
                      <tr key={i}>
                        <Link to={`/user/info/${key.id}/${pid}`}>
                          <td className="blue-color">
                            {key.username ? key.username : <span className="red-color"> Not Found </span>}
                          </td>
                        </Link>
                        <td>
                          {key?.sockets && key?.sockets[0]?.last_command ? (
                            key?.sockets[0]?.last_command
                          ) : (
                            <span className="red-color"> Not Found </span>
                          )}
                        </td>
                        <td>
                          {key?.sockets && key?.sockets[0]?.ip_address ? (
                            key.sockets[0].ip_address
                          ) : (
                            <span className="red-color"> Not Found </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default ProjectDetails;
